//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.main-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  background-color: white;
}

.main-container {
  max-width: 1364px;
  margin: 0 auto;
}

.custom-box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}