// Background lighten

@each $color, $value in $theme-colors {
  .bg-#{$color}-lighten {
    @include bg-variant-light($value);
  }
}

.custom-bg-strong-green {
  background-color: $custom-strong-green-color;
}

.custom-bg-light-gray {
  background-color: $custom-light-gray-color;
}

.custom-bg-light-yellow {
  background-color: $custom-light-yellow-color;
}

.custom-bg-light-green {
  background-color: $custom-lightest-green-color;
}

.custom-bg-strong-blue {
  background-color: $custom-strong-blue-color;
}

.custom-bg-strong-orange {
  background-color: $custom-strong-orange-color;
}

.custom-bg-strong-pink {
  background-color: $custom-strong-pink-color;
}

.custom-bg-medium-gray {
  background-color: $custom-medium-gray-color;
}

.custom-bg-strong-gray {
  background-color: $custom-strong-gray-color;
}