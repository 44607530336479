//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

.custom-btn {
  font-size: 16px;
}

.custom-btn.btn-green-action {
  &:hover, &:active, :focus {
    background-color: #249f90 !important;
    border-color: #249f90;
  }
}

.custom-btn.btn-white, .btn-white-action {
  &:hover, &:active, &:focus {
    background-color: #f8f9fa !important;
    border-color: #f8f9fa;
  }
}

.custom-btn.custom-bg-light-yellow {
  &:hover, &:active, &:focus {
    background-color: #f7ef7f;
    border-color: #f7ef7f;
  }
}

.custom-btn.custom-bg-light-green {
  &:hover, &:active, &:focus {
    background-color: #80f9ea;
    border-color: #80f9ea;
  }
}

.custom-btn.custom-bg-strong-blue {
  &:hover, &:active, &:focus {
    background-color: #03bff7;
    border-color: #03bff7;
  }
}

.custom-btn.custom-bg-strong-orange {
  &:hover, &:active, &:focus {
    background-color: #f7af3b;
    border-color: #f7af3b;
  }
}

.custom-btn.custom-bg-strong-pink {
  &:hover, &:active, &:focus {
    background-color: #fb9f9f;
    border-color: #fb9f9f;
  }
}

.custom-btn.custom-bg-medium-gray {
  &:hover, &:active, &:focus {
    background-color: #cdcbcb;
    border-color: #cdcbcb;
  }
}

.custom-btn.custom-bg-strong-gray {
  &:hover, &:active, &:focus {
    background-color: #a9a6a6;
    border-color: #a9a6a6;
  }
}

.custom-btn.custom-bg-original-white {
  &:hover, &:active, &:focus {
    background-color: #ebe9e9;
    border-color: #ebe9e9;
  }
}