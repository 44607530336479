.room-management {
  font-size: $primary-font-size;
  font-family: $font-family-roboto;
  color: $primary-text-color;
  // start select-room-type
  .select-room-type {
    font-family: $font-family-mulish;
    margin: 13px 0 16px;
    &-container {
      display:flex;
      justify-content: space-between;
      align-items: center;
    }
    &-left-content {
      display: flex;
      align-items: center;
      gap: 0 28px;
    }
    &-label {
      font-size: 24px;
      font-weight: 400;
    }
    &-display-type {
      gap: 0 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $custom-light-white-color;
      padding: 12px 14px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10px;    
      .custom-btn {
        padding: 5px 15px;
        border-radius: 5px;
        line-height: calc(20 / 16);
        &.btn-green {
          background-color: $custom-strong-green-color;
          color: white;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &.btn-white {
          padding: 5px 8px;
          border: 1px solid $custom-light-gray-color;
        }
      }
    }
    &-right-content {
      font-family: $font-family-mulish;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 6px 7px;
      gap: 0 10px;      
      border-radius: 7px;
      .custom-btn {
        color: $strong-text-color;
        width: 96px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        font-size: 12px; 
        line-height: calc(20 / 12);
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        &:last-child {
          color: $custom-lighter-white-color;
        }
      }
    }
  }
  // end select-room-type
  // start select-target-date
  .select-target-date {
    position: relative;
    margin-bottom: 20px;
    &-container {
      padding: 22px 0 22px 25px;
      background: $custom-light-white-color;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 18px;
    }
    &-select {
      display: flex;
      align-items: center;
    }
    &-label {
      margin-bottom: 0;
      margin-right: 2px;
      font-size: 18px;
      line-height: calc(21 / 18);
    }
    &-display-bar {
      display: flex;
      align-items: center;
      background: white;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      gap: 0 7px;
      padding: 0 8px;
      
      .custom-btn {
        font-weight: 700;
        font-size: 14px;
        font-family: $font-family-mulish;
        padding: 0;
        line-height: calc(20 / 14);
        color: white;
        background-color: $custom-strong-green-color;
        letter-spacing: 0.2px;
        padding: 4px 0;
      }      
    }
    &-next, &-previous {
      cursor: pointer;
      img {
        flex-shrink: 0;
      }      
    }
    &-today {
      color: white;
      background-color: $custom-strong-green-color;
      font-size: 14px;
      font-weight: 700;
      line-height: calc(20 / 14);
      font-family: $font-family-mulish;
      padding: 4px 11px;
      border-radius: 7px;
    }
    .react-datepicker {
      position: absolute;
      left: 136px;
      margin-top: -20px;
    }
  }
}
// end select-target-date
// start room-list-table
.room-list-table {
  margin-bottom: 34px;
  padding-bottom: 34px;
  &-container {
    border-radius: 20px;
    border: 2px solid #828282;
    padding-top: 20px;
    min-height: 770px;
  }
  &-content {
    
    table {
      text-align: center;
      thead {
        background-color: $custom-lighter-green-color;
        border: none !important;
        tr {
          border: none !important;
          th {
            border: none !important;
            font-size: 18px;
            padding: 12px 0;
            color: white;
            font-weight: 400;
            line-height: calc(21 / 18);
          }
        }
      }
      tbody {
        border: none !important;
        tr {
          &:first-child {
            td {
              .room-status {
                margin-top: 18px;
              }
              p {
                margin-bottom: 0;
                margin-top: 8px;
              }
            }
          }
          border-bottom: 1px solid black;
          &:last-child {
            border-bottom: none;
          }
          
          td {
            border: none !important;
            vertical-align: middle;
            padding: 0;
            font-size: 18px;
            line-height: calc(21 / 18);
            color: $primary-text-color;
            .date {
              font-size: 14px;
              line-height: calc(20 / 14);
              font-family: $font-family-mulish;
              font-weight: 700;
              margin-bottom: 0;
            }
            .room-status {
              width: 104px;
              padding: 9px 0;
              margin: 6px 0 11px 0;
              font-size: 14px;
              line-height: calc(12 / 14);
              font-weight: 500;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              color: $strong-text-color;
            }
          }
          .actions-column {
            width: 20%;
          }
          .actions {
            display: flex;
            justify-content: end;
            gap: 0 11px;
            margin-right: 20px;
            .custom-btn {
              font-size: $primary-font-size;
              line-height: calc(20 / 16);
              font-family: $font-family-mulish;
              font-weight: 400;
              color: $primary-text-color;
              padding: 5px 6px;
              border-radius: 5px;
              border: 1px solid $custom-light-gray-color;
            }
          }
        }
      }
    }
  }
  &-label {
    margin: 0 0 13px 18px;
    font-size: 18px;
    line-height: calc(21 / 18);
    font-weight: 400;
  }
}

// for ipad
@media screen and (max-width: 1023.98px) {
  .select-room-type {
    .select-room-type-left-content {
      gap: 0 8px;
      margin-right: 8px;
      
      .select-room-type-label {
        text-align: center;
      }
    }
    .select-room-type-right-content {
      .custom-btn {
        width: 60px;
        font-size: 11px;
      }
      .bigger-custom-btn {
        width: 86px;
      }
      .medium-custom-btn {
        width: 68px;
      }
    }
  }
  .room-list-table {
    .room-list-table-content {
      table {
        thead tr th {
          font-size: 14px;
          vertical-align: middle;
          &.people-number {
            width: 11%;
          }
          &.user-name {
            width: 10%;
          }
          &.room-number {
            width: 6%;
          }
          &.reservation-reception-date {
            width: 12%;
          }
          &.reservation-medium {
            width: 10%;
          }
          &.expected-arrival {
            width: 10%;
          }
          &.actions-column {
            width: 26%;
          }
        }
        tbody tr td .room-status {
          width: 100px;
          font-size: 13px;
          margin-left: 4px;
        }
        tbody tr td {
          font-size: 13px;
          .date {
            font-size: 13px;
          }
        }
        tbody tr .actions {
          margin-top: 4px;
          margin-bottom: 4px;
          margin-right: 10px;
          flex-direction: column;
          gap: 4px 0;
          .custom-btn {
            font-size: 12px;
          }
        }
      }
    }
  }

  .room-management {
    .select-target-date .react-datepicker {
      left: 98px;
    }
    .select-room-type-container {
      flex-direction: column;
      align-items: start;

      .select-room-type-left-content {
        margin-bottom: 8px;
      }
    }
  }
}

// for ipad pro
@media screen and (min-width: 1024px) and (max-width: 1279.98px) {
  .select-room-type {
    .select-room-type-left-content {
      gap: 0 8px;
      margin-right: 8px;
      
      .select-room-type-label {
        text-align: center;
      }
    }
  }

  .room-list-table {
    .room-list-table-content {
      table {
        thead tr th {
          font-size: $primary-font-size;
          &.reservation-reception-date {
            width: 10%;
          }
          &.people-number {
            width: 10%;
          }
        }
        tbody tr .actions {
          margin-top: 4px;
          margin-bottom: 4px;
          margin-right: 10px;
          flex-direction: column;
          gap: 8px 0;
        }
        tbody tr td .room-status {
          margin-left: 8px;
        }
        tbody tr td {
          font-size: 15px;
          .date {
            font-size: 14px;
          }
        }
      }
    }
  }
  .room-management {
    .select-target-date .react-datepicker {
      left: 98px;
    }
    .select-room-type-container {
      flex-direction: column;
      align-items: start;

      .select-room-type-left-content {
        margin-bottom: 8px;
      }
    }
  }
}
